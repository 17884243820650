import { default as carty0M6oXi1XvMeta } from "/home/ploi/staging.mcmodels.net/pages/cart.vue?macro=true";
import { default as _91id_93_45_91slug_930SDnB9eg53Meta } from "/home/ploi/staging.mcmodels.net/pages/categories/[id]-[slug].vue?macro=true";
import { default as forgot_45passwordSq3fWvb5VtMeta } from "/home/ploi/staging.mcmodels.net/pages/forgot-password.vue?macro=true";
import { default as _91id_93_45_91slug_93DGYWM3WN8mMeta } from "/home/ploi/staging.mcmodels.net/pages/guides/[id]-[slug].vue?macro=true";
import { default as index38TzoArxEZMeta } from "/home/ploi/staging.mcmodels.net/pages/guides/index.vue?macro=true";
import { default as vendor454p4if3maMeta } from "/home/ploi/staging.mcmodels.net/pages/guides/vendor.vue?macro=true";
import { default as indexOkFiPhMTBSMeta } from "/home/ploi/staging.mcmodels.net/pages/index.vue?macro=true";
import { default as aboutLTZf8LUtohMeta } from "/home/ploi/staging.mcmodels.net/pages/information/about.vue?macro=true";
import { default as privacy4H9yTLKakaMeta } from "/home/ploi/staging.mcmodels.net/pages/information/privacy.vue?macro=true";
import { default as terms2UG51HQZAZMeta } from "/home/ploi/staging.mcmodels.net/pages/information/terms.vue?macro=true";
import { default as loginTkrtozFcK7Meta } from "/home/ploi/staging.mcmodels.net/pages/login.vue?macro=true";
import { default as _91id_93_45_91slug_93moKos9HwU2Meta } from "/home/ploi/staging.mcmodels.net/pages/news/[id]-[slug].vue?macro=true";
import { default as indexTzKcmFvvnHMeta } from "/home/ploi/staging.mcmodels.net/pages/news/index.vue?macro=true";
import { default as _91token_93XsWnHCCzlaMeta } from "/home/ploi/staging.mcmodels.net/pages/password-reset/[token].vue?macro=true";
import { default as _91slug_93moIt72WxmkMeta } from "/home/ploi/staging.mcmodels.net/pages/products/[id]/[slug].vue?macro=true";
import { default as indexZ8fARmfQdxMeta } from "/home/ploi/staging.mcmodels.net/pages/products/index.vue?macro=true";
import { default as indexdxjDhQX0wvMeta } from "/home/ploi/staging.mcmodels.net/pages/profile/index.vue?macro=true";
import { default as _91id_938Uqwz0tsPCMeta } from "/home/ploi/staging.mcmodels.net/pages/profile/orders/[id].vue?macro=true";
import { default as index2dGItr2hhkMeta } from "/home/ploi/staging.mcmodels.net/pages/profile/orders/index.vue?macro=true";
import { default as securityHeEYa9Pe8vMeta } from "/home/ploi/staging.mcmodels.net/pages/profile/security.vue?macro=true";
import { default as registere5SI1vfw83Meta } from "/home/ploi/staging.mcmodels.net/pages/register.vue?macro=true";
import { default as _91slug_93gOcSjDgHYxMeta } from "/home/ploi/staging.mcmodels.net/pages/vendors/[id]/[slug].vue?macro=true";
import { default as index77U28Bvk2bMeta } from "/home/ploi/staging.mcmodels.net/pages/vendors/index.vue?macro=true";
import { default as component_45stubUiFnuSk9BCMeta } from "/home/ploi/staging.mcmodels.net/node_modules/nuxt/dist/pages/runtime/component-stub.js?macro=true";
import { default as component_45stubUiFnuSk9BC } from "/home/ploi/staging.mcmodels.net/node_modules/nuxt/dist/pages/runtime/component-stub.js";
export default [
  {
    name: "cart",
    path: "/cart",
    meta: carty0M6oXi1XvMeta || {},
    component: () => import("/home/ploi/staging.mcmodels.net/pages/cart.vue")
  },
  {
    name: "categories-id-slug",
    path: "/categories/:id()-:slug()",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/categories/[id]-[slug].vue")
  },
  {
    name: "forgot-password",
    path: "/forgot-password",
    meta: forgot_45passwordSq3fWvb5VtMeta || {},
    component: () => import("/home/ploi/staging.mcmodels.net/pages/forgot-password.vue")
  },
  {
    name: "guides-id-slug",
    path: "/guides/:id()-:slug()",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/guides/[id]-[slug].vue")
  },
  {
    name: "guides",
    path: "/guides",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/guides/index.vue")
  },
  {
    name: "guides-vendor",
    path: "/guides/vendor",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/guides/vendor.vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/index.vue")
  },
  {
    name: "information-about",
    path: "/information/about",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/information/about.vue")
  },
  {
    name: "information-privacy",
    path: "/information/privacy",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/information/privacy.vue")
  },
  {
    name: "information-terms",
    path: "/information/terms",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/information/terms.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: loginTkrtozFcK7Meta || {},
    component: () => import("/home/ploi/staging.mcmodels.net/pages/login.vue")
  },
  {
    name: "news-id-slug",
    path: "/news/:id()-:slug()",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/news/[id]-[slug].vue")
  },
  {
    name: "news",
    path: "/news",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/news/index.vue")
  },
  {
    name: "password-reset-token",
    path: "/password-reset/:token()",
    meta: _91token_93XsWnHCCzlaMeta || {},
    component: () => import("/home/ploi/staging.mcmodels.net/pages/password-reset/[token].vue")
  },
  {
    name: "products-id-slug",
    path: "/products/:id()/:slug()",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/products/[id]/[slug].vue")
  },
  {
    name: "products",
    path: "/products",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/products/index.vue")
  },
  {
    name: "profile",
    path: "/profile",
    meta: indexdxjDhQX0wvMeta || {},
    component: () => import("/home/ploi/staging.mcmodels.net/pages/profile/index.vue")
  },
  {
    name: "profile-orders-id",
    path: "/profile/orders/:id()",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/profile/orders/[id].vue")
  },
  {
    name: "profile-orders",
    path: "/profile/orders",
    meta: index2dGItr2hhkMeta || {},
    component: () => import("/home/ploi/staging.mcmodels.net/pages/profile/orders/index.vue")
  },
  {
    name: "profile-security",
    path: "/profile/security",
    meta: securityHeEYa9Pe8vMeta || {},
    component: () => import("/home/ploi/staging.mcmodels.net/pages/profile/security.vue")
  },
  {
    name: "register",
    path: "/register",
    meta: registere5SI1vfw83Meta || {},
    component: () => import("/home/ploi/staging.mcmodels.net/pages/register.vue")
  },
  {
    name: "vendors-id-slug",
    path: "/vendors/:id()/:slug()",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/vendors/[id]/[slug].vue")
  },
  {
    name: "vendors",
    path: "/vendors",
    component: () => import("/home/ploi/staging.mcmodels.net/pages/vendors/index.vue")
  },
  {
    name: component_45stubUiFnuSk9BCMeta?.name,
    path: "/how-to-tutorials",
    component: component_45stubUiFnuSk9BC
  },
  {
    name: component_45stubUiFnuSk9BCMeta?.name,
    path: "/model-category",
    component: component_45stubUiFnuSk9BC
  },
  {
    name: component_45stubUiFnuSk9BCMeta?.name,
    path: "/shop",
    component: component_45stubUiFnuSk9BC
  },
  {
    name: component_45stubUiFnuSk9BCMeta?.name,
    path: "/how-to-tutorials/:pathMatch(.*)",
    component: component_45stubUiFnuSk9BC
  },
  {
    name: component_45stubUiFnuSk9BCMeta?.name,
    path: "/model-category/:pathMatch(.*)",
    component: component_45stubUiFnuSk9BC
  },
  {
    name: component_45stubUiFnuSk9BCMeta?.name,
    path: "/shop/:pathMatch(.*)",
    component: component_45stubUiFnuSk9BC
  },
  {
    name: component_45stubUiFnuSk9BCMeta?.name,
    path: "/author/:pathMatch(.*)",
    component: component_45stubUiFnuSk9BC
  },
  {
    name: component_45stubUiFnuSk9BCMeta?.name,
    path: "/model-tag",
    component: component_45stubUiFnuSk9BC
  },
  {
    name: component_45stubUiFnuSk9BCMeta?.name,
    path: "/about-us",
    component: component_45stubUiFnuSk9BC
  }
]